import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiGo,
  DiNodejs,
  DiReact,
  DiJava,
  DiPython,
  DiMsqlServer,
  DiMongodb,
  DiMysql,
  DiSqllite,
} from "react-icons/di";
import {
  SiPostgresql,
  SiExpress,
  SiSpringboot,
  SiSpring,
  SiGit,
  SiDocker,
  SiCsharp,
  SiJavascript,
  SiTypescript,
  SiNextdotjs,
  SiTailwindcss,
} from "react-icons/si";

const Techstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* Java */}
      <Col xs={2} md={2} className="tech-icons">
        <DiJava />
      </Col>
      {/* spring */}
      <Col xs={2} md={2} className="tech-icons">
        <SiSpring />
      </Col>
      {/* spring boot */}
      <Col xs={2} md={2} className="tech-icons">
        <SiSpringboot />
      </Col>
      {/* go */}
      <Col xs={2} md={2} className="tech-icons">
        <DiGo />
      </Col>
      {/* python */}
      <Col xs={2} md={2} className="tech-icons">
        <DiPython />
      </Col>

      {/* C# */}
      <Col xs={2} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      {/* JS */}
      <Col xs={2} md={2} className="tech-icons">
        <SiJavascript />
      </Col>
      {/* TS */}
      <Col xs={2} md={2} className="tech-icons">
        <SiTypescript />
      </Col>
      {/* nodejs */}
      <Col xs={2} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      {/* expressjs */}
      <Col xs={2} md={2} className="tech-icons">
        <SiExpress />
      </Col>
      {/* react */}
      <Col xs={2} md={2} className="tech-icons">
        <DiReact />
      </Col>
      {/* next.js */}
      <Col xs={2} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      {/* tailwindcss */}
      <Col xs={2} md={2} className="tech-icons">
        <SiTailwindcss />
      </Col>

      {/* mongodb*/}
      <Col xs={2} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      {/* postgresql */}
      <Col xs={2} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      {/* mysql */}
      <Col xs={2} md={2} className="tech-icons">
        <DiMysql />
      </Col>
      {/* msql */}
      <Col xs={2} md={2} className="tech-icons">
        <DiMsqlServer />
      </Col>
      {/* sqlite */}
      <Col xs={2} md={2} className="tech-icons">
        <DiSqllite />
      </Col>

      {/* git */}
      <Col xs={2} md={2} className="tech-icons">
        <SiGit />
      </Col>
      {/* docker */}
      <Col xs={2} md={2} className="tech-icons">
        <SiDocker />
      </Col>
    </Row>
  );
};

export default Techstack;
