import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/avatar.jpg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineMail } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";

const Home2 = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <div className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="blue"> INTRODUCE </span> MYSELF
            </h1>
            <div md={4} className="myAvtar">
              <Tilt>
                <img
                  src={myImg}
                  className="img-fluid framed"
                  alt="avatar"
                  height="400px"
                  width="400px"
                />
              </Tilt>
            </div>
            <div className="introduction-text">
              <p className="home-about-body">
                My name's Ali Mert. I studied at Istanbul University and have{" "}
                <b className="blue">
                  two years of experience as a Software Developer
                </b>
                .{" "}
                <b className="blue">
                  I enjoy optimizing database performance and ensuring data
                  integrity in backend systems.
                </b>{" "}
                I have a passion for technology and love to create things that
                live on the internet. I take great joy in transforming my
                imagination into reality through coding. Software development
                offers me the opportunity to express my creativity while solving
                complex problems.
              </p>
              <p className="home-about-body">
                Since childhood, I've been fascinated by technology. The ability
                of a tiny microchip to perform complex tasks and bring digital
                characters to life amazed me. This little piece of silicon had
                the power to connect people across great distances. My curiosity
                led me to tinker with gadgets and write simple programs, fueling
                my desire to unlock the mysteries of the technology that once
                seemed so elusive.
              </p>
              <p className="home-about-body">
                I have been playing basketball since I was 7 years old, and I
                played professionally from the age of 12. Unfortunately, due to
                the long pandemic period, I decided not to continue my
                basketball career professionally. Currently, I continue to play
                basketball as a hobby.
              </p>
              <p className="home-about-body">
                Today, I like to introduce myself as a{" "}
                <b className="blue">Software Developer</b>
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Find Me On</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/aliwert"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/alimerterdogan/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:alimert.err@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://leetcode.com/u/aliwert/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiLeetcode />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Home2;
